import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, Grid, Paper } from '@mui/material';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { Link } from 'react-router-dom';
import { channelSort } from '../services/channelServiceParams';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const categories = [
    {
        name: "Live",
        icon: <LiveTvIcon style={{ color: "red" }} />,
        link: `/channels?isLive=true&sortOrder=${channelSort.sortOrder.descending}&pageNumber=${1}`
    },
    {
        name: "Popular",
        icon: <WhatshotIcon style={{ color: "orange" }} />,
        link: `/channels?sortOrder=${channelSort.sortOrder.descending}&pageNumber=${1}`
    },
]
class Explore extends React.Component {
    render() {
        return (
            <Box p={0}>
                <Paper elevation={3}>
                    <Box p={1}>
                        <Grid container>
                            {
                                categories.map(category => {
                                    return (
                                        <Grid item p={.5}>
                                            <Link to={category.link}><Button variant='outlined' size="large">{category.name}&nbsp;{category.icon}</Button></Link>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(Explore);