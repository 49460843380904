import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import * as Hls from 'hls.js';

const createStreamScreen = (scene, options) => {
    const mesh = BABYLON.MeshBuilder.CreatePlane('wall', { height: 50, width: 160 }, scene);
    mesh.position.y = 25;
    mesh.position.x = 0;
    mesh.position.z = 99;
    mesh.rotation = BABYLON.Vector3.Zero();
    mesh.rotation.z = Math.PI;
    //mesh.rotation.y = degreesToRadians(90);
    mesh.physicsImpostor = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, restitution: 0 }, scene);

    let videoMat = new BABYLON.StandardMaterial("textVid", scene);
    videoMat.backFaceCulling = false;
    var video = document.querySelector('video');
    var videoTexture = new BABYLON.VideoTexture('video', video, scene, true, true);
    videoMat.emissiveTexture = videoTexture;
    videoMat.diffuseTexture = videoTexture;
    videoMat.diffuseColor = new BABYLON.Color3(0,0,0)
    videoMat.emissiveColor = new BABYLON.Color3(0,0,0)
    videoMat.specularColor = new BABYLON.Color3(0,0,0)
    mesh.material = videoMat;
    var htmlVideo = videoTexture.video;
    if (Hls.isSupported()) {
        var hls = new Hls();
        hls.loadSource(options.stream);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
            mesh.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                    function (event) {
                        htmlVideo.play();
                    })
            );
        });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = options.stream;
        video.addEventListener('loadedmetadata', function () {
            mesh.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger,
                    function (event) {
                        htmlVideo.play();
                    })
            );
        });
    }
}

export default createStreamScreen;