import React from 'react';
import { connect } from 'react-redux';
import { Box, Paper } from '@mui/material';

class Streams extends React.Component {
    render() {
        return (
            <Box p={0}>
                <Paper elevation={3}>
                    <Box p={1}>
                        
                    </Box>
                </Paper>
            </Box>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(Streams);