import React from 'react';
import { connect } from 'react-redux';
import { Alert, Box, Button, Divider, Input, Paper, Snackbar } from '@mui/material';
import _assets from '../../services/assetService';
import _channels from '../../services/channelService';
import _users from '../../services/userService';
import SaveIcon from '@mui/icons-material/Save';
import { updateUserInfo } from '../../redux/actions/userInfoActions'

class AccountEditor extends React.Component {
    state = {
        messageOpen: false,
        message: "",
        messageType: "info",

        profileImageId: "",
        profileFileName: "",
        profileFilePreviewUrl: "",
        profileFile: undefined,
    }
    profilePicUpload = (e) => {
        const _this = this;
        if (e.target.files) {
            let file = e.target.files[0];
            let previewUrl = URL.createObjectURL(file)
            _assets.postFile(file).then(asset => {
                if (asset) {
                    this.setState({ 
                        profileFile: file, 
                        profileFileName: file.name, 
                        profileFilePreviewUrl: previewUrl,
                        profileImageId: asset.id,
                    })
                }
                else {
                    this.setState({
                        messageOpen: true,
                        messageType: "error",
                        message: "Something went wrong :(",
                    })
                }
            });
        }
    }
    openMessage = () => {
        this.setState({ messageOpen: true })
    }
    closeMessage = () => {
        this.setState({ messageOpen: false })
    }

    updateProfile = () => {
        const _this = this;
        _users.updateProfileImage(this.state.profileImageId).then(res => {
            if (res) {
                _this.props.updateUserInfo(res);
                this.setState({
                    messageOpen: true,
                    messageType: "success",
                    message: "Account information updated!",
                })
            }
            else {
                this.setState({
                    messageOpen: true,
                    messageType: "error",
                    message: "Something went wrong!",
                })
            }
        })
        
    }

    componentDidMount() {
        const _this = this;
        if (_this.props.userInfo) {
            _this.setState({
                profileImageId: _this.props.userInfo.profileImageId || "",
                profileFileName: "",
                profileFilePreviewUrl: _this.props.userInfo.profileImageId ? _assets.getFileUrl(_this.props.userInfo.profileImageId) : "",
                profileFile: undefined,
            })
        }
    }
    render() {
        const _this = this;
        const headerTextStyle = {
            margin: 0,
        }
        const gridItemStyle = {
            display: "flex",
            alignItems: "center"
        }
        const tdHeaderStyle = {
            borderRight: "solid black 1px",
            width: "200px"
        }
        const tdValueStyle = {
            padding: "2em",
        }
        const user = _this.props.user;
        const channel = _this.state.channel;
        return (
            <React.Fragment>
                <h4 style={{marginBottom:".5em"}}>Account</h4>
                <Divider />
                <Box p={2}>
                    <Paper elevation={3}>
                        <Box p={2}>
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={tdHeaderStyle}>
                                        <h5 style={headerTextStyle}>Profile Picture</h5>
                                    </td>
                                    <td style={tdValueStyle}>
                                        <img width="100px" height="100px" style={{ marginRight: "1em" }} src={this.state.profileFilePreviewUrl || "/content/images/logo-glow.png"}></img>
                                        <Button variant="contained" component="label">
                                            Upload File
                                            <input type="file" hidden onChange={this.profilePicUpload} />
                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={tdHeaderStyle}>
                                        <h5 style={headerTextStyle}>Username</h5>
                                    </td>
                                    <td style={tdValueStyle}>
                                        <Input disabled type="text" style={{ width: "100%" }} value={this.props.user.userName}></Input>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={tdHeaderStyle}>
                                        <h5 style={headerTextStyle}>Email</h5>
                                    </td>
                                    <td style={tdValueStyle}>
                                        <Input disabled type="text" style={{ width: "100%" }} value={this.props.user.email}></Input>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={tdHeaderStyle}>
                                    </td>
                                    <td style={tdValueStyle}>
                                        <Button variant="contained" component="label" onClick={_this.updateProfile}>
                                            <SaveIcon /> Save
                                        </Button>
                                    </td>
                                </tr>
                            </table>
                        </Box>
                    </Paper>
                </Box>
                <Snackbar open={_this.state.messageOpen} autoHideDuration={6000} onClose={_this.closeMessage}>
                    <Alert onClose={_this.closeMessage} severity={_this.state.messageType} sx={{ width: '100%' }}>
                        {_this.state.message}
                    </Alert>
                </Snackbar>
            </React.Fragment>
        );
    }
}

const mapState = (state) => { return { user: state.user, userInfo: state.userInfo } };
const mapDispatch = { updateUserInfo};

export default connect(mapState, mapDispatch)(AccountEditor);