import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Divider, Grid, IconButton, InputAdornment, InputBase, List, ListItem, ListItemAvatar, ListItemText, Paper, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import VideoPlayer from '../../components/videoPlayer';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import Canvas from '../../components/canvas';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import _channels from '../../services/channelService';
import { withNavigation } from '../../utility/withNavigation';
import _assets from '../../services/assetService';
import ChatInterface from './chatInterface';
import { STREAM_URL, VENUE_CLIENT_URL } from '../../services/config';
import VisibilityIcon from '@mui/icons-material/Visibility';
import _channelViewers from '../../services/channelViewerService';
import { DeviceUUID } from 'device-uuid';
import Linkify from 'react-linkify';
import { isMobile } from '../../utility/utility';

class Channel extends React.Component {
    state = {
        mode: "stream", // stream, game, vr
        channel: {},
        channelPictureUrl: "",
        viewerCount: 0,
    }
    updateChannelViewers = () => {
        _channelViewers.getChannelViewers(this.props.params.id).then(viewers => {
            this.setState({ viewerCount: viewers })
        });
    }
    componentDidMount() {
        const _this = this;
        _channels.getChannelById(this.props.params.id).then(channel => {
            this.setState({ channel: channel });
        })

        _this.updateChannelViewers();
        _this.interval = setInterval(_ => { _this.updateChannelViewers() }, 5000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        let channel = this.state.channel;
        let uuid = new DeviceUUID().get();
        const videoJsOptions = {
            autoplay: this.state.channel.isLive,
            controls: this.state.channel.isLive,
            liveui: true,
            // liveTracker: true,
            width: "100%",
            height: "100%",
            aspectRatio: "16:9",
            fluid: true,
            poster: _assets.getFileUrl(this.state.channel.channelPictureId),
            sources: [
                {
                    src: `${STREAM_URL}/api/Watch/${channel.channelId}.m3u8?&deviceId=${uuid}`,
                    type: 'application/x-mpegURL'
                },
            ],
        };
        let mode = this.state.mode;
        return (
            <Box p={0} style={{ height: "calc(100vh - 76px)" }}>
                <Box sx={{ display: isMobile() ? 'block' : 'flex', flexDirection: 'row' }} style={{ height: "100%" }}>
                    {/* <Grid xs={1}></Grid> */}
                    <Box sx={{ flexGrow: 1 }} className="scroller" style={{ height: isMobile() ? "auto" : "100%", overflow: "auto" }}>
                        <Box p={1} style={{maxWidth:"1920px", margin:"auto"}}>
                            {mode == "stream" && channel.channelId && <Box style={{borderRadius:"25px"}}><VideoPlayer {...videoJsOptions} isLive={this.state.channel.isLive} /></Box>}
                            {/* {mode == "game" && channel.channelId && <Canvas channelId={this.state.channel.channelId} isLive={this.state.channel.isLive} channelPictureId={this.state.channel.channelPictureId} />} */}
                            <Box p={1}>
                                <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                                    <Grid item xs={8}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Avatar style={{ display: "inline-block" }} sx={{ width: 50, height: 50 }} alt={channel.channelName} src={_assets.getFileUrl(this.state.channel.channelPictureId)} />
                                            </Grid>
                                            <Grid item>
                                                <h2 style={{ display: "inline-block" }}>{channel.channelName}</h2>
                                            </Grid>
                                            <Grid item>
                                                <Grid container alignItems="center" style={{ color: "grey" }}>
                                                    <Grid item>
                                                        &nbsp;&nbsp;&nbsp;&nbsp; <VisibilityIcon />
                                                    </Grid>
                                                    <Grid item>
                                                        &nbsp;&nbsp;{this.state.viewerCount} watching
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button href={`${VENUE_CLIENT_URL}/play/${channel.channelId}`} style={{ float: "right" }} variant='outlined' color="secondary" size="large">Enter Venue&nbsp;<SportsEsportsIcon /></Button>
                                        {/* {mode == "stream" && <Button onClick={() => this.setState({ mode: "game" })} style={{ float: "right" }} variant='outlined' color="secondary" size="large">Enter Venue&nbsp;<SportsEsportsIcon /></Button>} */}
                                        {/* {mode == "game" && <Button onClick={() => this.setState({ mode: "stream" })} style={{ float: "right" }} variant='outlined' color="secondary" size="large">View Stream&nbsp;<LiveTvIcon /></Button>} */}
                                    </Grid>
                                </Grid>
                            </Box>
                            {channel.description && <Paper elevation={3}>
                                <Box p={1} style={{ whiteSpace: "pre-line", overflowWrap:'break-word' }}>
                                    <Linkify
                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                    >
                                        {channel.description}
                                    </Linkify>
                                </Box>
                            </Paper>
                            }
                        </Box>
                    </Box>
                    <Box p={1} style={{ height: isMobile() ? "auto" : "100%", width: isMobile() ? "100%" : "400px" }}>
                        <ChatInterface channelId={this.props.params.id} />
                    </Box>
                </Box>

            </Box>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(withNavigation(Channel));