import React from 'react';
import { connect } from 'react-redux';
import _channels from '../../services/channelService';
import _assets from '../../services/assetService';
import ChannelCard from './channelCard';
import _follows from '../../services/followService';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { channelSort } from '../../services/channelServiceParams';
import { Link } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';

const categories = [
    {
        name: "Live",
        icon: <LiveTvIcon style={{ color: "red" }} />,
        link: `/channels?isLive=true&sortOrder=${channelSort.sortOrder.descending}&pageNumber=${1}`
    },
    {
        name: "Popular",
        icon: <WhatshotIcon style={{ color: "orange" }} />,
        link: `/channels?sortOrder=${channelSort.sortOrder.descending}&pageNumber=${1}`
    },
]

class ChannelList extends React.Component {
    state = {
        channels: [],
    }
    componentDidMount() {
        const _this = this;
        window.addEventListener('scroll', this.handleOnScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleOnScroll);
    }

    handleOnScroll = () => {
        const { documentElement, body } = document;
        const scrollTop = window.scrollY || documentElement.scrollTop || body.scrollTop;
        const scrollHeight = documentElement.scrollHeight || body.scrollHeight;
        const clientHeight = documentElement.clientHeight || window.innerHeight;
    
        if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && this.props.channels.length >= this.props.previousChannelSize) {
            this.props.getMoreData();
        }
    }

    render() {
        const _this = this;
        const follows = _this.props.userFollows;
        return (
            <React.Fragment>
                {
                    _this.props.channels?.length ? _this.props.channels.map(channel => {
                        return (<ChannelCard channel={channel} follow={follows.find(f => f.channelId == channel.channelId)} />)
                    })
                    : <Box p={2}>Nothing here. Check back later.. ✌️</Box>
                }

            </React.Fragment>
        );
    }
}

const mapState = (state) => { return { user: state.user, userFollows: state.userFollows } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(ChannelList);