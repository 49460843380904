import { combineReducers } from "redux";
import user from "./userReducer";
import userFollows from "./userFollowReducer";
import userInfo from "./userInfoReducer";
import userChannel from "./userChannelReducer";
import app from "./appReducer";

export default combineReducers({ 
    app,
    user,
    userFollows,
    userInfo,
    userChannel,
});
