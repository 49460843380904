import * as BABYLON from 'babylonjs';
import { v4 as uuidv4 } from 'uuid';

export class Player {
    id = uuidv4();
    body = undefined;
    constructor(scene, position) {
        const body = this.body = new BABYLON.MeshBuilder.CreateSphere("player-body", { diameter: 2 }, scene)
        body.position = position
        body.visibility = 0
        const head = this.head = new BABYLON.MeshBuilder.CreateSphere("player-head", { diameter: 2 }, scene)
        this.right = new BABYLON.MeshBuilder.CreateSphere("player-right", { diameter: 1 }, scene)
        this.right.position = new BABYLON.Vector3(-1, 0, 0)
        this.right.visibility = 0
        this.camera = new BABYLON.UniversalCamera("player-camera", new BABYLON.Vector3(0, 0, -1), scene)
        this.right.parent = this.head
        this.camera.parent = this.head
        this.scene = scene;
        this.scene.registerBeforeRender(e => {
            head.position.x = body.position.x
            head.position.y = body.position.y + 5
            head.position.z = body.position.z
        })

        body.physicsImpostor = new BABYLON.PhysicsImpostor(body, BABYLON.PhysicsImpostor.SphereImpostor, { mass: 40, restitution: 0 }, scene);
    }
    getFront() {
        const fuc = this.head.absolutePosition.subtract(this.camera.globalPosition)
        fuc.y = 0
        return fuc.normalize()
    }
    getBack() {
        const fuc = this.camera.globalPosition.subtract(this.head.absolutePosition)
        fuc.y = 0
        return fuc.normalize()
    }
    getRight() {
        const fuc = this.head.absolutePosition.subtract(this.right.absolutePosition)
        fuc.y = 0
        return fuc.normalize()
    }
    getLeft() {
        const fuc = this.right.absolutePosition.subtract(this.head.absolutePosition)
        fuc.y = 0
        return fuc.normalize()
    }
    isOnGround() {
        const info = this.scene.pickWithRay(new BABYLON.Ray(this.body.position, new BABYLON.Vector3(0, -1, 0)), e => {
            return !(e === this.body)
        })
        return info.hit && (Math.round(info.pickedPoint.y * 1000) / 1000 + 0.1) >= (this.body.getBoundingInfo().minimum.y + this.body.position.y)
    }
}