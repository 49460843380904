import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { closeMenu } from "../redux/actions/appActions";
import { isMobile } from "./utility";

export const withNavigation = (Component) => {
    return props => {
        const params = useParams();
        const [searchParams] = useSearchParams();
        const navigate = useNavigate();
        const sideMenuOpen = useSelector((state) => state.app.sideMenuOpen)
        const dispatch = useDispatch();

        const customNav = (args) => {
            if (isMobile()) { // if mobile close menu on navigate
                if (sideMenuOpen) {
                    dispatch(closeMenu());
                }
            }

            navigate(args);
        }
        return <Component {...props} params={params} searchParams={searchParams} navigate={customNav} />;
    };
}