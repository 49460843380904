import _channels from "../services/channelService"
import _follows from "../services/followService"

export const degreesToRadians = (degrees) => { return degrees * (Math.PI / 180) }
export const radiansToDegrees = (radians) => { return radians * (180 / Math.PI) }

export const updateFollows = (followChange) => {
    _follows.getFollows().then(async (follows) => {
        let followChannels = [];
        for (let follow of follows) {
          let channel = await _channels.getChannelById(follow.channelId);
          followChannels.push({
            ...follow,
            ...channel,
          });
        }
        followChange(followChannels);
      })
}

export const isMobile = () => {
 return window.innerWidth <= 768;
}