import React from 'react';
import { connect } from 'react-redux';
import { app_login, app_logout } from '../redux/actions/userActions';
import { withNavigation } from '../utility/withNavigation';
import { _userManager } from './userManager';

class AuthCallback extends React.Component {
    componentDidMount(){
      const _this = this;
      _userManager.signinSilentCallback().then((user) => {
      }).catch(function (e) {
        console.error(e);
      })
    }
    render() {
      return ""
    }
  }

  
const mapState = (state) => { return { user: state.user } };
const mapDispatch = { app_login, app_logout };
export default connect(mapState, mapDispatch)(withNavigation(AuthCallback));