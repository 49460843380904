const STORAGE_KEY = "exec-user-channel";
const init = () => {
    let saved = localStorage.getItem(STORAGE_KEY);
    if (saved)
        saved = JSON.parse(saved);
    return saved || {};
}

const reduce = (state, action) => {
    switch (action.type) {
        case "UPDATE_USERCHANNEL": {
            return {
                ...action.userChannel,
            };
        }
        default:
            return state;
    }
}
const reducer = (state = init(), action) => {
    state = reduce(state, action);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    return state;
}

export default reducer;