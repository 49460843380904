import React from 'react';
import { connect } from 'react-redux';
import { app_login, app_logout } from '../redux/actions/userActions';
import { withNavigation } from '../utility/withNavigation';
import { _userManager } from './userManager';

class AuthCallback extends React.Component {
  componentDidMount() {
    const _this = this;
    _userManager.signinRedirectCallback().then((user) => {
      _this.props.app_login({
        // id_token: user.id_token,
        // access_token: user.access_token,
        userId: user.profile.userId,
        email: user.profile.email,
        userName: user.profile.userName,
      })
      _this.props.navigate("/")
    }).catch(function (e) {
      //console.log(e);
      _this.props.navigate("/");
    })
  }
  render() {
    return ""
  }
}


const mapState = (state) => { return { user: state.user } };
const mapDispatch = { app_login, app_logout };
export default connect(mapState, mapDispatch)(withNavigation(AuthCallback));