import { _userManager } from "../auth/userManager";
import * as signalR from "@microsoft/signalr";
import { CHAT_URL } from "./config";

const TIMEOUT = 5000;
let connection = undefined;

const getAccessToken = () => {
    return _userManager.getUser()
        .then(user => user.access_token)
        .catch(_ => "");
}
const onreconnecting = (error) => {
    console.error(error);
}
const onreconnected = (connectionId) => {

}
const onclose = (error) => {
    console.error(error);
}
const _chat = {

    start: (getAllMessages, getMessage) => {
        return getAccessToken().then(accessToken => {
            connection = new signalR.HubConnectionBuilder()
                //.withUrl(CHAT_URL)
                //.withUrl(CHAT_URL, { accessTokenFactory: getAccessToken })
                .withUrl(`${CHAT_URL}?access_token=${accessToken}`)
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            connection.onreconnecting(onreconnecting);
            connection.onreconnected(onreconnected);
            connection.onclose(onclose);
            connection.on("ReceiveGroupMessages", getAllMessages);
            connection.on("ReceiveGroupMessage", getMessage);

            // connection.onclose(async () => {
            //     await start();
            // })
            try {
                return connection.start().catch(err => {
                    console.log(err);
                    return new Promise(res => {
                        setTimeout(_ => {
                            console.log("retrying to connect chat");
                            return res(_chat.start(getAllMessages, getMessage));
                        }, 1000)
                    })                

            });
            } catch (err) {
                console.log(err);
                // setTimeout(_this.start, TIMEOUT);
            }
        })

    },

    stop: () => {
        return connection.stop();
    },

    joinGroup: (groupName) => {
        if (connection?.state === signalR.HubConnectionState.Connected)
            return connection.invoke("JoinGroup", groupName);
        return new Promise(_ => true, _ => true);
    },

    leaveGroup: (groupName) => {
        if (connection?.state === signalR.HubConnectionState.Connected)
            return connection.invoke("LeaveGroup", groupName);
        return new Promise(_ => true, _ => true);
    },

    getAllMessages: (groupName) => {
        if (connection?.state === signalR.HubConnectionState.Connected)
            return connection.invoke("GetGroupMessages", groupName);
    },

    send: (groupName, message) => {
        try {
            if (connection?.state === signalR.HubConnectionState.Connected)
                return connection.invoke("SendMessageToGroup", groupName, message);
        } catch (err) {
            console.error(err);
        }
    }
}

export default _chat;