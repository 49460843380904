import { Box, Button, Divider, Grid } from "@mui/material";
import React from "react";
import { withNavigation } from "../../utility/withNavigation";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { connect } from "react-redux";
import _follows from "../../services/followService";
import Follow from "./sidenav-helpers/follow";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { channelSort } from "../../services/channelServiceParams";

class SideNav extends React.Component {
    state = {

    }
    componentDidMount() {

    }
    render() {
        const sideMenuOpen = this.props.app.sideMenuOpen;
        return (
            <Box style={{ height: "100%", width: "100%", backgroundColor: "black" }}>
                <Box p={1} style={{ height: "40px" }}>
                    <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item>
                            <TravelExploreIcon />&nbsp;
                        </Grid>
                        {sideMenuOpen &&

                            <Grid item>
                                <b>Explore</b>
                            </Grid>
                        }
                    </Grid>
                </Box>
                <Button onClick={_ => this.props.navigate(`/channels?isLive=true&sortOrder=${channelSort.sortOrder.descending}&pageNumber=${1}&pageSize=${10}`)} p={1} style={{ width: "100%", textAlign: "left", height: "40px", minWidth: "40px"}}>
                    <Grid container justifyContent="flex-start" alignItems="center" style={{ flexWrap: "nowrap" }}>
                        <Grid item>
                            <LiveTvIcon style={{ color: "red", fontSize: "1.5em" }} />&nbsp;
                        </Grid>
                        {sideMenuOpen &&

                            <Grid item>
                                <span>Live Channels</span>
                            </Grid>
                        }
                    </Grid>
                </Button>
                <Button onClick={_ => this.props.navigate(`/channels?sortOrder=${channelSort.sortOrder.descending}&pageNumber=${1}&pageSize=${10}`)} p={1} style={{ width: "100%", textAlign: "left", height: "40px", minWidth: "40px"}}>
                    <Grid container justifyContent="flex-start" alignItems="center" style={{ flexWrap: "nowrap" }}>
                        <Grid item>
                            <WhatshotIcon style={{ color: "orange", fontSize: "1.5em" }} />&nbsp;
                        </Grid>
                        {sideMenuOpen &&

                            <Grid item>
                                <span>Popular</span>
                            </Grid>
                        }
                    </Grid>
                </Button>

                {(this.props.user?.userId && this.props.userFollows.length) ?
                    <Box>
                        <hr />
                        <Divider />
                        <Box p={1} style={{ height: "40px" }}>
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid item>
                                    <FavoriteIcon />&nbsp;
                                </Grid>
                                {sideMenuOpen &&

                                    <Grid item>
                                        <b>Following</b>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                        {this.props.userFollows.sort(c => c.isLive ? -1 : 1).slice(0, 5).map(f =>
                            <Follow channel={f} mobileSideMenuNavigate={this.props.mobileSideMenuNavigate} />
                        )}
                    </Box>
                    : ""
                }
            </Box>
        );
    }
}

const mapState = (state) => { return { user: state.user, userFollows: state.userFollows, app: state.app } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(withNavigation(SideNav));