import React from 'react';
import { connect } from 'react-redux';
import { Alert, Box, Button, Grid, Input, Paper, Snackbar, TextField } from '@mui/material';
import HelpPopover from '../../components/helper/helpPopover';
import _assets from '../../services/assetService';
import _channels from '../../services/channelService';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { updateUserChannel } from '../../redux/actions/userChannelActions'

const HiddenStreamKey = "00000000-0000-0000-0000-000000000000";

class ChannelEditor extends React.Component {
    state = {
        messageOpen: false,
        message: "",
        messageType: "info",

        streamKey: HiddenStreamKey,
        showStreamKey: false,

        hasChannel: false,
        channel: {
            channelId: "",
            userId: "",
            channelName: "",
            description: "",
            channelPictureId: "",
            channelBackgroundPictureId: "",
            streamKey: "",
        },

        channelFileName: "",
        channelFilePreviewUrl: "",
        channelFile: undefined,

        channelBackgroundFileName: "",
        channelBackgroundFilePreviewUrl: "",
        channelBackgroundFile: undefined,
    }
    channelPicUpload = (e) => {
        const _this = this;
        if (e.target.files) {
            let file = e.target.files[0];
            let previewUrl = URL.createObjectURL(file)
            _assets.postFile(file).then(asset => {
                if (asset) {
                    _this.state.channel.channelPictureId = asset.id;
                    _this.setState({
                        channelFile: file,
                        channelFileName: file.name,
                        channelFilePreviewUrl: previewUrl,
                        channel: _this.state.channel,
                    })
                }
                else {
                    this.setState({
                        messageOpen: true,
                        messageType: "error",
                        message: "Something went wrong :(",
                    })
                }
            });
        }
    }
    channelBackgroundPicUpload = (e) => {
        const _this = this;
        if (e.target.files) {
            let file = e.target.files[0];
            let previewUrl = URL.createObjectURL(file)
            _assets.postFile(file).then(asset => {
                if (asset) {
                    _this.state.channel.channelBackgroundPicureId = asset.id;
                    _this.setState({
                        channelBackgroundFile: file,
                        channelBackgroundFileName: file.name,
                        channelBackgroundFilePreviewUrl: previewUrl,
                        channel: _this.state.channel,
                    })
                }
                else {
                    this.setState({
                        messageOpen: true,
                        messageType: "error",
                        message: "Something went wrong :(",
                    })
                }
            });
        }
    }
    createChannel = () => {
        const _this = this;
        let channel = {
            channelName: this.props.user.userName,
            description: "",
            channelPictureId: "",
            channelBackgroundPicureId: "",
        }
        _channels.updateChannel(channel).then(c => {
            _this.props.updateUserChannel(c);
            if (c) {
                this.setState({
                    hasChannel: true,
                    channel: {
                        ...c
                    },
                    messageOpen: true,
                    messageType: "success",
                    message: "Channel created!",
                })
            }
            else {
                this.setState({
                    messageOpen: true,
                    messageType: "error",
                    message: "Something went wrong :(",
                })
            }
        })
    }
    updateChannel = () => {
        let channel = {
            channelName: this.state.channel.channelName || this.props.user.userName,
            description: this.state.channel.description || "",
            channelPictureId: this.state.channel.channelPictureId || "",
            channelBackgroundPicureId: this.state.channel.channelBackgroundPicureId || "",
        }
        _channels.updateChannel(channel).then(c => {
            if (c) {
                this.props.updateUserChannel(c);
                this.setState({
                    hasChannel: true,
                    channel: {
                        ...c
                    },
                    messageOpen: true,
                    messageType: "success",
                    message: "Channel updated!",
                })
                this.openMessage();
            }
            else {
                this.setState({
                    messageOpen: true,
                    messageType: "error",
                    message: "Something went wrong :(",
                })
            }

        })
    }

    setChannelProp = (e) => {
        let name = e.target.name;
        this.state.channel[name] = e.target.value;
        this.setState({ channel: this.state.channel });
    }

    openMessage = () => {
        this.setState({ messageOpen: true })
    }

    closeMessage = () => {
        this.setState({ messageOpen: false })
    }

    componentDidMount() {
        const _this = this;
        if (_this.props.userChannel?.channelId) {
            let c = _this.props.userChannel;
            _this.setState({
                hasChannel: true,
                channel: c,
                channelFilePreviewUrl: c.channelPictureId ?  _assets.getFileUrl(c.channelPictureId) : "",
                channelBackgroundFilePreviewUrl: c.channelBackgroundPictureId ?  _assets.getFileUrl(c.channelBackgroundPictureId) : "",
            })
        }
        else {
            _this.setState({ hasChannel: false })
        }
    }
    toggleStreamKeyVisibility = () => {
        const _this = this;
        if (_this.state.showStreamKey) {
            _this.setState({
                streamKey: HiddenStreamKey,
                showStreamKey: false,
            })
        }
        else {
            _channels.getStreamKey().then(streamKey => {
                if (streamKey) {
                    _this.setState({
                        streamKey: streamKey,
                        showStreamKey: true,
                    })
                }
                else {
                    _channels.setStreamKey().then(streamKey => {
                        if (streamKey) {
                            _this.setState({
                                streamKey: streamKey,
                                showStreamKey: true,
                            })
                        }
                    })
                }
            })
        }
    }

    resetStreamKey = () => {
        const _this = this;
        _channels.setStreamKey().then(streamKey => {
            if (streamKey) {
                _this.setState({
                    streamKey: streamKey,
                    showStreamKey: true,
                })
            }
        })
    }

    render() {
        const _this = this;
        const headerTextStyle = {
            margin: 0,
        }
        const gridItemStyle = {
            display: "flex",
            alignItems: "center"
        }
        const tdHeaderStyle = {
            borderRight: "solid black 1px",
            width: "200px"
        }
        const tdValueStyle = {
            padding: "2em",
        }
        const user = _this.props.user;
        const channel = _this.state.channel;
        return (
            <React.Fragment>
                <Box p={2}>
                    <Paper elevation={3}>
                        <Box p={2}>
                            {_this.state.hasChannel
                                ? (
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td style={tdHeaderStyle}>
                                                <h5 style={headerTextStyle}>Channel Picture</h5>
                                            </td>
                                            <td style={tdValueStyle}>
                                                <img width="100px" height="100px" style={{ marginRight: "1em" }} src={_this.state.channelFilePreviewUrl || "/content/images/logo-glow.png"}></img>
                                                <Button variant="contained" component="label">
                                                    Upload File
                                                    <input type="file" hidden onChange={this.channelPicUpload} />
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={tdHeaderStyle}>
                                                <h5 style={headerTextStyle}>Channel Background</h5>
                                            </td>
                                            <td style={tdValueStyle}>
                                                <img width="100px" height="100px" style={{ marginRight: "1em" }} src={_this.state.channelBackgroundFilePreviewUrl || "/content/images/logo-glow.png"}></img>
                                                <Button variant="contained" component="label">
                                                    Upload File
                                                    <input type="file" hidden onChange={this.channelBackgroundPicUpload} />
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={tdHeaderStyle}>
                                                <Grid container>
                                                    <Grid style={gridItemStyle} item>
                                                        <h5 style={headerTextStyle}>Stream Key</h5>
                                                    </Grid>
                                                    <Grid style={gridItemStyle} item>
                                                        <HelpPopover id={"stream-key"} message={"Use this key in a video streaming broadcaster to stream to your channel"}></HelpPopover>
                                                    </Grid>
                                                </Grid>
                                            </td>
                                            <td style={tdValueStyle}>
                                                <TextField type={!this.state.showStreamKey ? "password" : "text"} variant="standard" value={this.state.streamKey} style={{ width: "100%" }}></TextField>
                                                <Button variant="contained" component="label" onClick={_this.toggleStreamKeyVisibility} style={{ marginTop:"1em", marginRight: "1em" }}>
                                                    {
                                                        !this.state.showStreamKey
                                                            ? <span><VisibilityIcon /> Show</span>
                                                            : <span><VisibilityOffIcon /> Hide</span>
                                                    }
                                                </Button>
                                                <Button variant="contained" component="label" onClick={_this.resetStreamKey} style={{ marginTop:"1em" }}>
                                                    <RestartAltIcon /> Reset
                                                </Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={tdHeaderStyle}>
                                                <Grid container>
                                                    <Grid style={gridItemStyle} item>
                                                        <h5 style={headerTextStyle}>Name</h5>
                                                    </Grid>
                                                    <Grid style={gridItemStyle} item>
                                                        <HelpPopover id={"name"} message={"Name of your channel"}></HelpPopover>
                                                    </Grid>
                                                </Grid>
                                            </td>
                                            <td style={tdValueStyle}>
                                                <Input name="channelName" onChange={_this.setChannelProp} type="text" style={{ width: "100%" }} value={channel.channelName}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={tdHeaderStyle}>
                                                <Grid container>
                                                    <Grid style={gridItemStyle} item>
                                                        <h5 style={headerTextStyle}>Description</h5>
                                                    </Grid>
                                                    <Grid style={gridItemStyle} item>
                                                        <HelpPopover id={"description"} message={"Description of the channel"}></HelpPopover>
                                                    </Grid>
                                                </Grid>
                                            </td>
                                            <td style={tdValueStyle}>
                                                <Input name="description" onChange={_this.setChannelProp} type="text" multiline rows={10} style={{ width: "100%" }} value={channel.description}></Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={tdHeaderStyle}>
                                            </td>
                                            <td style={tdValueStyle}>
                                                <Button variant="contained" component="label" onClick={_this.updateChannel}>
                                                    <SaveIcon /> Save
                                                </Button>
                                            </td>
                                        </tr>
                                    </table>
                                )
                                : (
                                    <Button variant="contained" component="label" onClick={_this.createChannel}>
                                        <WhatshotIcon /> Get Started!
                                    </Button>
                                )
                            }
                            <Snackbar open={_this.state.messageOpen} autoHideDuration={6000} onClose={_this.closeMessage}>
                                <Alert onClose={_this.closeMessage} severity={_this.state.messageType} sx={{ width: '100%' }}>
                                    {_this.state.message}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </Paper>
                </Box>
            </React.Fragment>
        );
    }
}

const mapState = (state) => { return { user: state.user, userChannel: state.userChannel } };
const mapDispatch = { updateUserChannel };

export default connect(mapState, mapDispatch)(ChannelEditor);