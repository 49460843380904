import React from 'react';
import { IconButton, Popover, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
class HelpPopover extends React.Component {
    state = {
        open: false,
        anchorEl: undefined,
    }
    open = (event) => {
        this.setState({ open: true, anchorEl: event.currentTarget })
    }
    close = () => {
        this.setState({ open: false })
    }
    render() {
        return (
            <React.Fragment>
                <IconButton onClick={this.open}><HelpIcon /></IconButton>
                <Popover
                    id={this.props.id}
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.close}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }}>{this.props.message}</Typography>
                </Popover>
            </React.Fragment>
        );
    }
}

export default HelpPopover;