import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import _assets from '../../services/assetService';
import _channels from '../../services/channelService';
import AccountEditor from './accountEditor';

class Account extends React.Component {
    state = { }
    render() {
        return (
            <Box p={2}>
                <AccountEditor />
            </Box>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(Account);