import React from 'react';
import { connect } from 'react-redux';
import { Box, Divider, Grid, Paper } from '@mui/material';
import { withNavigation } from '../utility/withNavigation';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import CircleIcon from '@mui/icons-material/Circle';
import _channels from '../services/channelService';
import { channelSort } from '../services/channelServiceParams';
import _assets from '../services/assetService';
import ChannelCard from './channels/channelCard';
import _follows from '../services/followService';
import LiveCard from './channels/liveCard';
import _search from '../services/searchService';

class HomePage extends React.Component {
    state = {
        liveChannels: [],
        channels: [],
    }
    componentDidMount() {
        const _this = this;
        const hasUser = _this.props.user.userId;
        _search.getChannelsSorted("", false, channelSort.sortOrder.descending, 1, 10).then(c => {
            this.setState({channels: c || [] })
        });
        _search.getChannelsSorted("", true, channelSort.sortOrder.descending, 0, 10).then(c => {
            this.setState({liveChannels: c || []})
        });
    }
    render() {
        const _this = this;
        const hasUser = _this.props.user.userId;
        const follows = this.props.userFollows;
        return (
            <Box p={0}>
                <Paper elevation={3}>
                {_this.state.liveChannels?.length ? <Box p={1}>
                        <h4 style={{marginBottom:".5em"}}><CircleIcon fontSize="large" style={{ color: "red", animation:"fade 2s linear infinite" }} /> Live Channels</h4>
                        <Divider />
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            {
                                _this.state.liveChannels.map(channel => {
                                    return (<LiveCard channel={channel} follow={follows.find(f => f.channelId == channel.channelId)}/>)
                                })
                            }

                        </Grid>
                    </Box>
                    : ""
                    }
                    <Box p={1}>
                        <h4 style={{marginBottom:".5em"}}><WhatshotIcon fontSize="large" style={{ color: "orange" }} /> Popular Channels</h4>
                        <Divider />
                        <Grid container direction="row"
                            justifyContent="center"
                            alignItems="center">
                            {
                                _this.state.channels.map(channel => {
                                    return (<ChannelCard channel={channel} follow={follows.find(f => f.channelId == channel.channelId)}/>)
                                })
                            }
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

const mapState = (state) => { return { user: state.user,  userFollows: state.userFollows} };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(withNavigation(HomePage));