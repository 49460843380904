import { Autocomplete, Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import _channels from "../../services/channelService";
import _assets from "../../services/assetService";
import { withNavigation } from "../../utility/withNavigation";
import CircleIcon from '@mui/icons-material/Circle';
import { channelSort } from "../../services/channelServiceParams";
import _search from "../../services/searchService";

const OPTIONS_LIMIT = 8;
class SearchBox extends React.Component {
    state = {
        loading: false,
        open: false,
        inputValue: '',
        options: [],
    }
    componentDidMount() {

    }

    search = (inputValue) => {
        if (inputValue) {
            this.setState({ loading: true });
            _search.getChannelsSorted(inputValue, false, 1, channelSort.sortOrder.descending, 10).then(channels => {

                this.setState({
                    loading: false,
                    options: channels.sort(c => c.isLive? -1 : 1).slice(0, OPTIONS_LIMIT)
                });
            })
        }
    }

    navigateToChannelSearch = () => {
        this.props.navigate(`/channels?searchTerm=${this.state.inputValue}&sortOrder=${channelSort.sortOrder.descending}&pageNumber=${1}`);
    }

    render() {
        return (
            <div className='m-auto' style={{ width: '100%', maxWidth:"1000px", minWidth: "200px", paddingLeft:"25px", paddingRight:"20px" }}>
                <Grid container>
                    <Grid item xs>
                        <Autocomplete
                            options={this.state.options}
                            filterOptions={(x) => x} // disable filtering
                            onOpen={() => {
                                this.setState({ open: true });
                            }}
                            onClose={() => {
                                this.setState({ open: false });
                            }}
                            onChange={(event, newValue) => {
                                this.props.navigate(`/channel/${newValue.channelId}`);
                            }}
                            onInputChange={(event, newValue) => {
                                this.setState({ inputValue: newValue });
                                this.search(newValue);
                            }}
                            inputValue={this.state.inputValue}
                            isOptionEqualToValue={(option, value) => option.channelId === value.channelId}
                            getOptionLabel={(option) => option.channelName}
                            noOptionsText={"Nothing found 😞"}
                            open={this.state.open}
                            clearOnBlur={false}
                            renderOption={(props, channel) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <img
                                                loading="lazy"
                                                style={{ borderRadius: "50%", backgroundColor: "black" }}
                                                component="img"
                                                width="40"
                                                height="40"
                                                src={channel.channelPictureId ? _assets.getFileUrl(channel.channelPictureId) : "/content/images/logo-glow.png"}
                                                alt={""}
                                            />
                                            &nbsp;
                                            <span>{channel.channelName}</span>
                                        </Grid>
                                        <Grid item>
                                            {channel.isLive ? <span style={{ float: 'right' }}><CircleIcon fontSize="small" style={{ color: "red", animation: "fade 2s linear infinite" }} />&nbsp;Live</span> : ""}
                                        </Grid>
                                    </Grid>

                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search"
                                    onKeyDown={e => { if (e.key === 'Enter') this.navigateToChannelSearch() }}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { borderTopRightRadius: '0', borderBottomRightRadius: '0' },
                                        endAdornment: (
                                            <React.Fragment>
                                                {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item sm={1}>
                        <Button onClick={this.navigateToChannelSearch} variant='outlined' color="secondary" style={{ height: '100%', borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}><SearchIcon /></Button>
                    </Grid>
                </Grid>

            </div>

        );
    }
}

export default withNavigation(SearchBox);