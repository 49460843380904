import { _userManager } from "../auth/userManager";

const getAccessToken = () => {
    return _userManager.getUser()
    .then(user => user.access_token)
    .catch(_ => "")
}
const getAuthorizationHeaders = async () => {
    const token = await getAccessToken();
    return token ? {
        "Authorization" : `Bearer ${token}`
    } : {};
}
const responseHandler = (response) => {
    if (response.status >= 400) {
        throw new Error(response.status)
    }
    else {
        if (!response.headers.get("Content-Type")){
            return;
        }
        else if (response.headers.get("Content-Type").includes("application/json")){
            return response.json();
        }
        else if (response.headers.get("Content-Type").includes("text/plain")){
            return response.text();
        }
        else {
            return response.blob();
        }
    }
}
const errorHandler = (error) => {
    if (error.message == 401) {
        _userManager.signinRedirect();
    }
    else {
        console.log(error);
    }
}

const _api = {
    getUrl: (baseUrl, path) => {
        return `${baseUrl}${path}`;
    },
    get: async (baseUrl, url) => {
        return fetch(`${baseUrl}${url}`, {
            method: 'GET',
            headers:{
                ... await getAuthorizationHeaders(),
            }
        })
        .then(responseHandler)
        .catch(errorHandler);
    },
    post: async (baseUrl, url, data) => {
        return fetch(`${baseUrl}${url}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                ... await getAuthorizationHeaders(),
                'Content-Type': 'application/json'
            }
        })
        .then(responseHandler)
        .catch(errorHandler);
    },
    patch: async (baseUrl, url, data) => {
        return fetch(`${baseUrl}${url}`, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                ... await getAuthorizationHeaders(),
                'Content-Type': 'application/json'
            }
        })
        .then(responseHandler)
        .catch(errorHandler);
    },
    delete: async (baseUrl, url) => {
        return fetch(`${baseUrl}${url}`, {
            method: 'DELETE',
            headers:{
                ... await getAuthorizationHeaders(),
            }
        })
        .then(responseHandler)
        .catch(errorHandler);
    },

    postFile: async (baseUrl, url, formData) => {
        return fetch(`${baseUrl}${url}`, {
            method: 'POST',
            body: formData,
            headers: {
                ... await getAuthorizationHeaders(),
            }
        })
        .then(responseHandler)
        .catch(errorHandler);
    },
}

export default _api;