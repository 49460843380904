import React from "react";
import videojs from "video.js";
import 'video.js/dist/video-js.css';

export default class VideoPlayer extends React.Component {
  componentDidMount() {
      this.videoNode = document.createElement("video");
      this.videoNode.width = "100%";
      this.videoNode.height = "100%";
      this.videoNode.classList.add("video-js");
      this.videoNode.style.cursor = "default";
      document.getElementById("video-player").appendChild(this.videoNode);
      this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
        
      });

    this.player.on("error", (error) => {
      // alert("Stream unavailable")
      console.log(error)
    })

    if (this.videoNode) {
      this.videoNode.setAttribute("webkit-playsinline", true);
      this.videoNode.setAttribute("playsinline", true);
    }
  }
   componentWillUnmount() {
    if (this.player && !this.player.isDisposed()) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div id="video-player" style={{boxShadow:"0 0 20px black"}}>
        {/* <video ref={node => (this.videoNode = node)} width="100%" height="100%" className="video-js" /> */}
        </div>
    );
  }
}
