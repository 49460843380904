import { _userManager } from "../auth/userManager";
import * as signalR from "@microsoft/signalr";
import { VENUE_HUB_URL } from "./config";

const TIMEOUT = 5000;
let connection = undefined;

const getAccessToken = () => {
    return _userManager.getUser()
        .then(user => user.access_token)
        .catch(_ => "");
}
const onreconnecting = (error) => {
    console.error(error);
}
const onreconnected = (connectionId) => {

}
const onclose = () => {
    //console.log("connection closed");
}
const _venueHub = {

    start: (callbacks) => {
        return getAccessToken().then(accessToken => {
            connection = new signalR.HubConnectionBuilder()
                //.withUrl(CHAT_URL)
                //.withUrl(CHAT_URL, { accessTokenFactory: getAccessToken })
                .withUrl(`${VENUE_HUB_URL}?access_token=${accessToken}`)
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();
            connection.onreconnecting(onreconnecting);
            connection.onreconnected(onreconnected);
            connection.onclose(onclose);
            connection.on("ReceiveJoinNotification", callbacks.playerJoin);
            connection.on("ReceiveLeaveNotification", callbacks.playerLeave);
            connection.on("ReceivePositionInformation", callbacks.updatePosition);

            // connection.onclose(async () => {
            //     await start();
            // })
            try {
                return connection.start().catch(err => console.log(err));
            } catch (err) {
                console.log(err);
                // setTimeout(_this.start, TIMEOUT);
            }
        })

    },

    stop: () => {
        return connection.stop();
    },

    playerJoin: (groupName, message) => {
        if (connection?.state === signalR.HubConnectionState.Connected)
            return connection.invoke("JoinGroup", groupName, message);
        return new Promise(_ => true, _ => true);
    },

    playerLeave: (groupName, message) => {
        if (connection?.state === signalR.HubConnectionState.Connected)
            return connection.invoke("LeaveGroup", groupName, message);
        return new Promise(_ => true, _ => true);
    },

    updatePosition: (groupName, message) => {
        try {
            if (connection?.state === signalR.HubConnectionState.Connected)
                return connection.invoke("SendPositionInformationToGroup", groupName, message);
        } catch (err) {
            console.error(err);
        }
    }
}

export default _venueHub;