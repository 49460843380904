import React from 'react';
import { connect } from 'react-redux';
import { Box, Checkbox, Paper, Button, Grid } from '@mui/material';
import _assets from '../../services/assetService';
import _channels from '../../services/channelService';
import { updateUserChannel } from '../../redux/actions/userChannelActions'
import { DataGrid, GridActionsCellItem, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import _venue from '../../services/venueService';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { VENUE_CLIENT_URL } from '../../services/config';
import { Link } from 'react-router-dom';

class VenuesEditor extends React.Component {
    state = {
        venues: [],
    }
    setActiveVenue = (event, venue) => {
        const _this = this;
        let checked = event.target.checked;
        let venueId = checked ? venue.id : undefined;
        _channels.updateChannel({
            ..._this.props.userChannel,
            activeVenueId: venueId,
        }).then(c => {
            _this.props.updateUserChannel(c);

        })
    }

    columns = [
        {
            field: 'pictureId',
            headerName: '',
            width: 200,
            editable: false,
            renderCell: (params) => (<img style={{ width: "100px", height: "100px" }} src={params?.value ? _venue.getFileUrl(params?.value) : "/content/images/logo-glow.png"} alt="logo"></img>)
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: false,
        },
        {
            field: 'dateModified',
            headerName: 'Date Modified',
            valueFormatter: params => `${new Date(params?.value).toLocaleDateString()} ${new Date(params?.value).toLocaleTimeString()}`,
            type: 'datetime',
            width: 250,
            editable: false,
        },
        {
            field: 'active',
            headerName: 'Active',
            type: 'boolean',
            width: 250,
            renderCell: (params) => (<Checkbox onChange={e => this.setActiveVenue(e, params.row)} checked={params?.value} size="large" color="success" />),
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: 250,
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<RocketLaunchIcon style={{ fontSize: "1.5em" }} />}
                        label="Launch"
                        className="textPrimary"
                        onClick={_ => window.location = `${VENUE_CLIENT_URL}/build/${id}`}
                        color="inherit"
                    />,
                    //   <GridActionsCellItem
                    //     icon={<DeleteIcon />}
                    //     label="Delete"
                    //     onClick={handleDeleteClick(id)}
                    //     color="inherit"
                    //   />,
                ];
            },
        },
        // {
        //   field: 'fullName',
        //   headerName: 'Full name',
        //   description: 'This column has a value getter and is not sortable.',
        //   sortable: false,
        //   width: 160,
        //   valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    componentDidMount() {
        const _this = this;
        _venue.getUserVenues().then(venues => {
            if (_this.props.userChannel.activeVenueId) {
                let activeVenue = venues.find(v => v.id == _this.props.userChannel.activeVenueId)
                if (activeVenue) {
                    activeVenue.active = true;
                }
            }
            _this.setState({ venues: venues });
        })
    }

    render() {
        const _this = this;
        const user = _this.props.user;
        const venueGridToolbar = () => {
            return (
                <GridToolbarContainer>
                    <Box p={1} style={{width:"100%"}}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                            <Button href={`${VENUE_CLIENT_URL}/build`} variant='outlined'><AddCircleIcon />&nbsp;Create New</Button>              

                            </Grid>
                            <Grid item>
                            <GridToolbarQuickFilter />

                            </Grid>
                        </Grid>
                    </Box>
                </GridToolbarContainer>
            );
        }

        return (
            <React.Fragment>
                <Box p={2}>
                    <Paper elevation={3}>
                        <Box p={2}>
                            <DataGrid
                                rows={_this.state.venues}
                                rowHeight={100}
                                columns={_this.columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 5,
                                        },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 20]}
                                slots={{ toolbar: venueGridToolbar  }}
                            // checkboxSelection
                            // disableRowSelectionOnClick
                            />
                        </Box>
                    </Paper>
                </Box>
            </React.Fragment>
        );
    }
}

const mapState = (state) => { return { user: state.user, userChannel: state.userChannel } };
const mapDispatch = { updateUserChannel };

export default connect(mapState, mapDispatch)(VenuesEditor);