import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store'
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#808080"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none'
                },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none'
                },
                '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none'
                },
                '& .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none'
                }
            },
        }
    },
}
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);
