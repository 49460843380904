import { VENUE_URL } from "./config";
import _api from "./serviceClient";
import { v4 as uuidv4 } from 'uuid';

const _venue = {
    getAllVenues: () => {
        return _api.get(VENUE_URL, `/Venue/GetAllVenues`);
    },
    saveVenue: (venue, userId) => {
        venue = {userId, id: uuidv4(), ...venue,}
        return _api.post(VENUE_URL, `/Venue/SaveVenue`, venue);
    },
    getVenue: (id) => {
        return _api.get(VENUE_URL, `/Venue/GetVenue?venueId=${id}`);
    },
    getUserVenues: () => {
        return _api.get(VENUE_URL, `/Venue/GetUserVenues`);
    },
    deleteVenue: (id) => {
        return _api.delete(VENUE_URL, `/Venue/DeleteVenueByVenueId?venueId=${id}`);
    },
    getFileUrl: (id) => {
        return _api.getUrl(VENUE_URL, `/Asset/GetAssetById?id=${id}`)
    },
}

export default _venue;
