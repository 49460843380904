import _api from "./serviceClient";
import { API_URL } from "./config";

const _users = {
    masterLogin: (username, password) => {
        let payload = {username, password};
        return _api.post(API_URL, `/User/MasterLogin`, payload);
    },
    getUsers: (id) => {
        return _api.get(API_URL, `/User/GetUsers`);
    },
    getUserInfo: () => {
        return _api.get(API_URL, '/User/GetUserInfo');
    },
    updateProfileImage: (profileImageId) => {
        let payload = {
            profileImageId: profileImageId
        }
        return _api.post(API_URL, `/User/UpdateUserProfileImage`, payload);
    },
    getUserProfilePictureByUserIdUrl: (userId) => {
        return _api.getUrl(API_URL, `/User/GetUserProfilePictureByUserId?userId=${userId}`);
    }
}

export default _users;