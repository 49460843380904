import _api from "./serviceClient";
import { API_URL } from "./config";

const _follows = {
    getFollows: () => {
        return _api.get(API_URL, `/Follow/GetUserFollows`);
    },
    createFollow: (channelId) => {
        let payload = {
            channelId: channelId
        }
        return _api.post(API_URL, `/Follow/CreateFollow`, payload);
    },
    deleteFollow: (followId) => {
        return _api.delete(API_URL, `/Follow/DeleteFollow?followId=${followId}`);
    }
}

export default _follows;