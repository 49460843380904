import _api from "./serviceClient";
import { API_URL } from "./config";

const _assets = {
    postFile: (file) => {
        var data = new FormData()
        data.append('file', file)
        return _api.postFile(API_URL, `/Asset/SaveAsset`, data);
    },
    getFileById: (id) => {

        return _api.get(API_URL, `/Asset/GetAssetById?id=${id}`);
    },
    getFileUrl: (id) => {
        return _api.getUrl(API_URL, `/Asset/GetAssetById?id=${id}`)
    }
}

export default _assets;