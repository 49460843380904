import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Route, Link, Outlet, Routes } from 'react-router-dom';
import './App.css';
import { Box, Button, IconButton } from '@mui/material';
import HomePage from './pages/home';
import Explore from './pages/explore';
import Channels from './pages/channels/channels';
import Streams from './pages/streams';
import Channel from './pages/channel/channel';
import { connect } from 'react-redux';
import { app_login, app_logout } from './redux/actions/userActions';
import { updateUserFollows } from './redux/actions/userFollowActions';
import { updateUserChannel } from './redux/actions/userChannelActions';
import { updateUserInfo } from './redux/actions/userInfoActions';
import LoginCallback from './auth/loginCallback';
import { withNavigation } from './utility/withNavigation';
import Account from './pages/account/account';
import { _userManager } from './auth/userManager';
import SilentLoginCallback from './auth/silentLoginCallback';
import MasterLogin from './pages/masterLogin';
import _channels from './services/channelService';
import SearchBox from './components/search/searchBox';
import Sidenav from './components/nav/sidenav';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import _follows from './services/followService';
import _users from './services/userService';
import _assets from './services/assetService';
import { updateFollows } from './utility/utility';
import TvIcon from '@mui/icons-material/Tv';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import SettingsIcon from '@mui/icons-material/Settings';
import ChannelSettings from './pages/account/channelSettings';
import ConstructionIcon from '@mui/icons-material/Construction';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { VENUE_CLIENT_URL } from './services/config';
import { toggleMenuOpen } from './redux/actions/appActions';

const isMobile = window.innerWidth <= 768

class App extends React.Component {
  state = {
    masterLogin: localStorage.getItem("master-login"),
  }
  masterLogin = () => {
    localStorage.setItem("master-login", true);
    this.setState({ masterLogin: true })
  }

  clickLogout = () => {
    this.props.app_logout();
    _userManager.signoutRedirect();
  }
  clickLogin = () => {
    _userManager.signinRedirect();
  }
  componentDidMount() {
    const _this = this;
    const loadUser = () => {
      _channels.getUserChannel().then(channel => {
        _this.props.updateUserChannel(channel);
      })
      _users.getUserInfo().then(ui => {
        _this.props.updateUserInfo(ui);
      })
      updateFollows(_this.props.updateUserFollows);
    }

    _userManager.getUser()
      .then(u => {
        if (u) {
          if (u.expired) {
            _userManager.signinSilent()
              .then(_ => {
                loadUser();
              })
              .catch(e => {
                _this.props.app_logout();
                _userManager.removeUser();
              })
          }
          else {
            loadUser();
          }
        }
        else {
          _this.props.app_logout();
          _userManager.removeUser();
        }
      })
      .catch(e => {
        _this.props.app_logout();
        _userManager.removeUser();
      })
  }
  render() {
    const _this = this;
    const hasUser = _this.props.user.userId;
    const isAdminUser = false;
    const sideMenuOpen = this.props.app.sideMenuOpen;
    const Navigator = () => (
      <React.Fragment>
        <Navbar sticky="top" bg="dark" variant="dark" expand="lg" style={{ justifyContent: "space-between", padding: "5px" }}>
          <IconButton onClick={() => this.props.toggleMenuOpen()}>
            <MenuIcon />
          </IconButton>
          <Navbar.Brand onClick={_ => this.props.navigate("/")} style={{ marginRight: 0 }}><img style={{ width: "50px", height: "50px" }} src="/content/images/logo-glow.png" alt="logo"></img></Navbar.Brand>
          <Nav className='m-auto' style={{ flexGrow: 1000 }}>
            <SearchBox />
          </Nav>
          <Nav style={{ paddingLeft: "10px" }}>
            {hasUser && isAdminUser ? <Nav.Link onClick={_ => this.props.navigate("/admin")} >Admin</Nav.Link> : ""}
            {hasUser &&
              <React.Fragment>
                <NavDropdown alignRight className='user-dropdown' menuVariant="dark" title={<React.Fragment>
                  <span>
                    <img src={_this.props.userInfo.profileImageId ? _assets.getFileUrl(_this.props.userInfo.profileImageId) : "/content/images/logo.png"} style={{ width: "50px", height: "50px", borderRadius: "50%" }}></img>
                    &nbsp;&nbsp;&nbsp;
                    {/* <span className="hide-on-mobile">{this.props.user.userName}</span> */}
                  </span>
                </React.Fragment>}>

                  <NavDropdown.Header>Account</NavDropdown.Header>
                  <NavDropdown.Item onClick={_ => this.props.navigate("/account")}><PersonIcon fontSize='sm' />&nbsp;Account</NavDropdown.Item>
                  <NavDropdown.Item onClick={this.clickLogout}><LogoutIcon fontSize='sm' />&nbsp;Logout</NavDropdown.Item>

                  <NavDropdown.Header>Channel</NavDropdown.Header>
                  {!this.props.userChannel || <NavDropdown.Item onClick={_ => this.props.navigate(`/channel/${this.props.userChannel.channelId}`)}><TvIcon fontSize='sm' />&nbsp;Channel</NavDropdown.Item>}
                  <NavDropdown.Item onClick={_ => this.props.navigate("/channel-settings?tab=channel")}><SettingsIcon fontSize='sm' />&nbsp;Settings</NavDropdown.Item>
                  <NavDropdown.Item onClick={_ => this.props.navigate("/channel-settings?tab=venues")}><ApartmentIcon fontSize='sm' />&nbsp;Venues</NavDropdown.Item>

                  <NavDropdown.Header>External Links</NavDropdown.Header>
                  <NavDropdown.Item href={`${VENUE_CLIENT_URL}/build`}><ConstructionIcon fontSize='sm' />&nbsp;Venue Builder</NavDropdown.Item>
                  <NavDropdown.Item href="https://vaudiox.com"><GraphicEqIcon fontSize='sm' />&nbsp;Vaudiox</NavDropdown.Item>
                </NavDropdown>
              </React.Fragment>

            }
            {!hasUser ? <Nav.Link onClick={this.clickLogin}><Button variant="contained">Login</Button></Nav.Link> : ""}
            {/* {!hasUser ? <Nav.Link onClick={this.clickRegister}><Button variant="outlined">Register</Button></Nav.Link> : ""} */}
          </Nav>
        </Navbar>
        <div style={{ display: "flex", flexGrow: "2" }}>
          <div style={{ display: "flex", height: "100%", width: "100%" }}>
            {!isMobile || (isMobile && sideMenuOpen) ? (
              <div style={{ width: isMobile ? "100%" : sideMenuOpen ? "200px" : "50px", backgroundColor: "black" }}>
                <Box style={{ position: "relative", padding: "3px" }}>
                  <div style={{ height: "50px" }}></div>
                  <Sidenav isMobile={isMobile} />
                </Box>
              </div>
            ) : ""
            }

            {!isMobile || (isMobile && !sideMenuOpen) ? (
              <div style={{ width: "100%", height: "100%" }}>
                <Outlet />
              </div>
            ) : ""
            }

          </div>

        </div>
      </React.Fragment>
    )

    let result = this.state.masterLogin ? (
      <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Routes>
          <Route path="/" element={<Navigator />}>
            <Route index element={<HomePage />} />
            <Route path="/channels" element={<Channels />} />
            <Route path="/channel/:id" element={<Channel />} />
            <Route path="/streams" element={<Streams />} />
            <Route path="/account" element={<Account />} />
            <Route path="/channel-settings" element={<ChannelSettings />} />
            <Route path="/auth/callback" element={<LoginCallback />} />
            <Route path="/auth/silentCallback" element={<SilentLoginCallback />} />
          </Route>
        </Routes>
      </div>
    )
      : (
        <MasterLogin masterLogin={this.masterLogin} />
      );

    return result;
  }
}

const mapState = (state) => { return { user: state.user, userInfo: state.userInfo, userChannel: state.userChannel, app: state.app } };
const mapDispatch = { app_login, app_logout, updateUserFollows, updateUserInfo, updateUserChannel, toggleMenuOpen };
export default connect(mapState, mapDispatch)(withNavigation(App));