import { isMobile } from "../../utility/utility";

const STORAGE_KEY = "exec-app";
const init = () => {
    let saved = localStorage.getItem(STORAGE_KEY);
    if (saved)
        saved = JSON.parse(saved);
    return saved || {
        sideMenuOpen: isMobile(),
    };
}

const reduce = (state, action) => {
    switch (action.type) {
        case "TOGGLE_MENU_OPEN": {
            return {
                ...state,
                sideMenuOpen: !state.sideMenuOpen,
            };
        }
        case "CLOSE_MENU": {
            return {
                ...state,
                sideMenuOpen: false,
            };
        }
        default:
            return state;
    }
}
const reducer = (state = init(), action) => {
    state = reduce(state, action);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    return state;
}

export default reducer;