import _api from "./serviceClient";
import { API_URL } from "./config";

const _search = {
    getChannels: () => {
        return _search.getChannelsSorted("", false, 0, 1, 10);
    },
    getChannelsSorted: (searchTerm, isLive, sortOrder, pageNumber, pageSize) => {
        let request = {
            channelSearchRequest: {
                searchTerm: searchTerm || "",
                pageNumber: pageNumber,
                pageSize: pageSize,
                filterOptions: {
                    isLive: isLive,
                    genres: undefined,
                    
                },
                sortOptions: {
                    isByName: undefined, // boolean
                    sortOrder: sortOrder, // 0 desc, 1 asc
                    
                },
            }
        }
        return _api.post(API_URL, `/Search/Search`, request).then(c => c?.channels);
    },
}

export default _search;