import React from 'react';
import { connect } from 'react-redux';
import _channels from '../../services/channelService';
import _assets from '../../services/assetService';
import _follows from '../../services/followService';
import ChannelList from './channelList';
import { withNavigation } from '../../utility/withNavigation';
import { Box, Divider, Grid, Paper } from '@mui/material';
import _search from '../../services/searchService';

class Channels extends React.Component {
    state = {
        channels: [],
        previousChannelSize: 0,
        pageSize:10,
        search: this.props.searchParams.get('searchTerm'),
        isLive: Boolean(this.props.searchParams.get('isLive')),
        sortOrder: this.props.searchParams.get('sortOrder'),
        pageNumber: this.props.searchParams.get('pageNumber')
    }
    componentDidMount() {
        this.search(this.state.search, this.state.isLive, this.state.sortOrder, this.state.pageNumber, this.state.pageSize);
    }

    search = (search, isLive, sortOrder, pageNumber, pageSize) => {
        const _this = this;
        _search.getChannelsSorted(search || "", isLive || false, sortOrder, pageNumber, pageSize).then(c => {
            _this.setState({channels: c })
        });
    }

    getMoreData = () => {
        this.setState({pageSize: this.state.pageSize += 10});
        this.search(this.state.search, this.state.isLive, this.state.sortOrder, this.state.pageNumber, this.state.pageSize);
        this.setState({previousChannelSize: this.state.previousChannelSize += this.state.channels.length});
    }

    render() {
        return (
            <Box p={0}>
                <Paper elevation={3}>
                    <Box p={1}>
                        <h4 style={{marginBottom:".5em"}}>Channels</h4>
                        <Divider />
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <ChannelList user={this.props.user} getMoreData={this.getMoreData} channels={this.state.channels} previousChannelSize={this.state.previousChannelSize}/>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(withNavigation(Channels));