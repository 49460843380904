import React from 'react';
import { connect } from 'react-redux';
import { Box, Tabs, Tab, TabContent, Divider } from '@mui/material';
import _assets from '../../services/assetService';
import _channels from '../../services/channelService';
import ChannelEditor from './channelEditor';
import VenuesEditor from './venuesEditor';
import { withNavigation } from '../../utility/withNavigation';

class ChannelSettings extends React.Component {
    state = {
        tab: 'channel',
    }
    componentDidMount() {
        let tab = this.props.searchParams.get('tab');
        if (tab) {
            this.setState({tab: tab});
        }
        else {
            this.props.navigate(`/channel-settings?tab=channel`);
        }
    }
    render() {
        return (
            <Box p={2}>
                <h4 style={{ marginBottom: ".5em" }}>Channel Settings</h4>
                <Divider />
                <Tabs value={this.state.tab} onChange={(e, t) => this.setState({ tab: t })} indicatorColor="secondary" textColor="inherit">
                    <Tab label="Channel Information" onClick={() => this.props.navigate(`/channel-settings?tab=channel`)} value="channel" style={{ outline: "none" }} />
                    <Tab label="Venues" value="venues" onClick={() => this.props.navigate(`/channel-settings?tab=venues`)} style={{ outline: "none" }} />
                </Tabs>
                {this.state.tab == "channel" ?
                    <Box>
                        <ChannelEditor />
                    </Box>
                    : ""
                }
                {this.state.tab == "venues" ?
                    <Box>
                        <VenuesEditor />
                    </Box>
                    : ""
                }
            </Box>
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(withNavigation(ChannelSettings));