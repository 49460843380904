import React from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import _channels from '../../../services/channelService';
import _follows from '../../../services/followService';
import _assets from '../../../services/assetService';
import { withNavigation } from '../../../utility/withNavigation';

class FollowItem extends React.Component {
    state = {

    }
    componentDidMount() {

    }

    render() {
        const _this = this;
        const channel = _this.props.channel;
        return channel ?
            (
                <Button onClick={() => {this.props.navigate(`/channel/${channel.channelId}`)}} className="sidenav-button" style={{ color: "white", width: "100%", textAlign: "left", height: "40px", minWidth: "40px" }}>
                    <Grid container justifyContent="flex-start" alignItems="center" style={{ flexWrap: "nowrap" }}>
                        <Grid item>
                            <img
                                style={{ borderRadius: "50%", backgroundColor: "black", width: "20px", height: "20px" }}
                                src={channel.channelPictureId ? _assets.getFileUrl(channel.channelPictureId) : "/content/images/logo-glow.png"}
                                alt={channel.channelName + " image"}
                            />
                            &nbsp;
                        </Grid>
                        {this.props.app.sideMenuOpen &&

                            <Grid item flexGrow={1}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        {channel.channelName}
                                    </Grid>
                                    <Grid item>
                                        {channel.isLive ? <span style={{ float: 'right' }}><CircleIcon fontSize="small" style={{ color: "red", animation: "fade 2s linear infinite" }} /></span> : ""}
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Button>
            ) : "";
    }
}

const mapState = (state) => { return { user: state.user, app: state.app } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(withNavigation(FollowItem));