import React from "react";
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import * as CANNON from "cannon";
import { StandardMaterial } from "babylonjs";
import createStreamScreen from "./scene/streamScreen";
import { STREAM_URL } from "../services/config";
import _venueHub from "../services/venueHubService";
import { Player } from "./venue/player";
import {DeviceUUID} from 'device-uuid';
import _assets from "../services/assetService";

export default class Canvas extends React.Component {
    engine = undefined;
    scene = undefined;
    canvas = undefined;
    ground = undefined;
    remotePlayers = [];
    state = { 

    }

    getStreamUrl() {
        let uuid = new DeviceUUID().get();
        return `${STREAM_URL}/api/Watch/${this.props.channelId}.m3u8&deviceId=${uuid}`;
    }

    createScene = () => {
        const _this = this;
        let fps = 60;
        _this.canvas = document.getElementById("renderCanvas");
        _this.engine = new BABYLON.Engine(_this.canvas, true);
        _this.scene = new BABYLON.Scene(_this.engine);
        _this.scene.ambientColor = new BABYLON.Color3(1, 1, 1);
        _this.scene.gravity = new BABYLON.Vector3(0, -.75, 0);
        _this.scene.collisionsEnabled = true;
        _this.scene.enablePhysics(new BABYLON.Vector3(0, -80, 0), new BABYLON.CannonJSPlugin(true, 100, CANNON));
        var options = new BABYLON.SceneOptimizerOptions(fps, 0);
        options.addOptimization(new BABYLON.HardwareScalingOptimization(0, 1));
        var optimizer = new BABYLON.SceneOptimizer(_this.scene, options);

        const size = 48;
        const height = 8 * size;
        const width = 6 * size;
        const sphere = BABYLON.MeshBuilder.CreateIcoSphere('icosphere', { radius: 2 }, _this.scene)
        sphere.position = new BABYLON.Vector3(20, 40, 0)
        sphere.material = new StandardMaterial("white", _this.scene);
        sphere.material.emissiveColor = new BABYLON.Color3(1, 0, 0)
        var light = new BABYLON.PointLight("pointLight", new BABYLON.Vector3(20, 40, 0), _this.scene);
        light.diffuse = new BABYLON.Color3(1, 0, 0)
        light.intensity = .5
        const sphere2 = BABYLON.MeshBuilder.CreateIcoSphere('icosphere', { radius: 2 }, _this.scene)
        sphere2.position = new BABYLON.Vector3(-20, 40, 0)
        sphere2.material = new StandardMaterial("white", _this.scene);
        sphere2.material.emissiveColor = new BABYLON.Color3(0, 0, 1)
        var light2 = new BABYLON.PointLight("pointLight", new BABYLON.Vector3(-20, 40, 0), _this.scene);
        light2.diffuse = new BABYLON.Color3(0, 0, 1)
        light2.intensity = .5

        const stoneTexture = new BABYLON.Texture("https://upload.wikimedia.org/wikipedia/commons/1/1e/Cement_texture.jpg", _this.scene);
        const roadmaterialpt = new BABYLON.Texture("https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Moon_texture.jpg/2560px-Moon_texture.jpg", _this.scene);
        _this.ground = BABYLON.MeshBuilder.CreatePlane('ground', { height, width }, _this.scene)
        _this.ground.rotation.x = Math.PI / 2
        _this.ground.material = new BABYLON.StandardMaterial("road", _this.scene)
        _this.ground.material.diffuseTexture = stoneTexture
        _this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(_this.ground, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, restitution: 0 }, _this.scene)

        let groundMaterial = new BABYLON.StandardMaterial("textVid", _this.scene);
        groundMaterial.backFaceCulling = false;
        const ground2 = BABYLON.MeshBuilder.CreatePlane('ground2', { height, width }, _this.scene)
        ground2.material = groundMaterial;
        ground2.rotation.x = Math.PI / 2
        ground2.y = 200
        ground2.material = new BABYLON.StandardMaterial("road", _this.scene)
        ground2.material.diffuseTexture = roadmaterialpt
        ground2.physicsImpostor = new BABYLON.PhysicsImpostor(_this.ground, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, restitution: 0 }, _this.scene)

        createStreamScreen(_this.scene, {
            stream: this.getStreamUrl(),
        });

        let wallMaterial = new BABYLON.StandardMaterial("textVid", _this.scene);
        wallMaterial.diffuseTexture = stoneTexture;
        wallMaterial.backFaceCulling = false;
        wallMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1)

        let createWall = (wall) => {
            const mesh = BABYLON.MeshBuilder.CreatePlane('wall', { height: wall.height, width: wall.width }, _this.scene);
            mesh.position.x = wall.position.x;
            mesh.position.y = wall.position.y;
            mesh.position.z = wall.position.z;
            mesh.rotation = BABYLON.Vector3.Zero();
            mesh.rotation.x = wall.rotation.x;
            mesh.rotation.y = wall.rotation.y;
            mesh.rotation.z = wall.rotation.z;
            //mesh.rotation.y = degreesToRadians(90);
            mesh.physicsImpostor = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, restitution: 0 }, _this.scene);


            mesh.material = wallMaterial;
        }
        createWall({
            position: {
                x: 0,
                y: 20,
                z: 100,
            },
            rotation: {
                x: 0,
                y: 0,
                z: Math.PI,
            },
            height: 100,
            width: 300,
        })
        createWall({
            position: {
                x: 0,
                y: 20,
                z: -100,
            },
            rotation: {
                x: 0,
                y: Math.PI,
                z: Math.PI,
            },
            height: 100,
            width: 300,
        })
        createWall({
            position: {
                x: 100,
                y: 20,
                z: 0,
            },
            rotation: {
                x: 0,
                y: Math.PI / 2,
                z: Math.PI,
            },
            height: 100,
            width: 300,
        })
        createWall({
            position: {
                x: -100,
                y: 20,
                z: 0,
            },
            rotation: {
                x: 0,
                y: -Math.PI / 2,
                z: Math.PI,
            },
            height: 100,
            width: 300,
        })
        createWall({
            position: {
                x: 0,
                y: 50,
                z: 0,
            },
            rotation: {
                x: -Math.PI / 2,
                y: 0,
                z: 0,
            },
            height: 300,
            width: 300,
        })
    }

    createPlayer = () => {
        const _this = this;
        _this.player = new Player(_this.scene, new BABYLON.Vector3(0, 20, 0))
        _this.scene.registerBeforeRender(e => {
            const force = 4 * _this.engine.getDeltaTime()
            if (document.pointerLockElement === _this.canvas) {
                if ( _this.player.isOnGround()) {
                    let velocity = new BABYLON.Vector3(0, 0, 0)
                    if (keys[32]) {
                        _this.player.body.physicsImpostor.applyImpulse(new BABYLON.Vector3(0, 2000, 0),  _this.player.body.getAbsolutePosition())
                    } else {
                        if (keys[87]) {
                            velocity = velocity.add( _this.player.getFront())
                        }
                        if (keys[83]) {
                            velocity = velocity.add( _this.player.getBack())
                        }
                        if (keys[65]) {
                            velocity = velocity.add( _this.player.getLeft())
                        }
                        if (keys[68]) {
                            velocity = velocity.add( _this.player.getRight())
                        }


                        _this.player.body.physicsImpostor.setLinearVelocity(velocity.normalize().multiplyByFloats(force, force, force))
                    }
                }
                if (keys[9]) {
                    _this.player.body.position = new BABYLON.Vector3(0, 20, 0);
                }

                _this.updatePosition();
            }


        });
        
        /* add mousemove listener, change camera lookat */
        const mousemove = e => {
            _this.player.head.rotate(BABYLON.Axis.X, e.movementY / 1000, BABYLON.Space.LOCAL)
            _this.player.head.rotate(BABYLON.Axis.Y, e.movementX / 1000, BABYLON.Space.WORLD)
        }
        document.addEventListener('pointerlockchange', function () {
            if (document.pointerLockElement === _this.canvas) {
                document.addEventListener("mousemove", mousemove, false);
            } else {
                document.removeEventListener("mousemove", mousemove, false);
            }
        }, false);
        /* add key listener */
        const keys = {}
        window.addEventListener('keydown', e => {
            keys[e.keyCode] = true
            if (e.keyCode === 9) e.preventDefault()
        })
        window.addEventListener('keyup', e => {
            keys[e.keyCode] = false
            if (e.keyCode === 9) e.preventDefault()
        })
        /* window resize */
        window.addEventListener('resize', e => _this.engine.resize())
        /* requestPointerLock */
        _this.scene.onPointerDown = e => _this.canvas.requestPointerLock()

    }

    startVR = async () => {
        const _this = this;
        _this.scene.createDefaultXRExperienceAsync({
            // define floor meshes
            floorMeshes: [_this.ground]
        });
        const xrHelper = await xrHelper.baseExperience.onStateChangedObservable.add((state) => {

            if (state === BABYLON.WebXRState.IN_XR) {

                _this.scene.activeCamera.position.y = 5;

            }

        });
    }

    createRemotePlayer = () => {
        const _this = this;
        return BABYLON.MeshBuilder.CreateIcoSphere('icosphere', { radius: 3 }, _this.scene);
    }

    remotePlayerJoin = (data) => {
        const _this = this;
        if (data.playerId != _this.player.id) {
            let player = _this.createRemotePlayer();
            player.playerId = data.playerId;
            player.position.x = Number(data.position.x);
            player.position.y =  Number(data.position.y);
            player.position.z =  Number(data.position.z);
            _this.remotePlayers.push(player);
        }      
    }

    remotePlayerLeave = (data) => {
        const _this = this;
        if (data.playerId != _this.player.id) {
            let player = _this.remotePlayers.find(p => p.playerId == data.playerId);
            if (player) {
                player.dispose();
            }
            _this.remotePlayers = _this.remotePlayers.filter(p => p.playerId == data.playerId);
        }
    }

    remoteUpdatePosition = (data) => {
        const _this = this;
        if (data.playerId != _this.player.id) {
            let player = _this.remotePlayers.find(p => p.playerId == data.playerId);
            if (!player) {
                player = _this.createRemotePlayer();
                _this.remotePlayers.push(player);
            }
            player.playerId = data.playerId;
            player.position.x = Number(data.position.x);
            player.position.y = Number(data.position.y);
            player.position.z = Number(data.position.z);
        }
    }

    updatePosition = () => {
        const _this = this;
        const data = {
            playerId: _this.player.id,
            position: {
                x: _this.player.body.position.x.toString(),
                y: _this.player.body.position.y.toString(),
                z: _this.player.body.position.z.toString(),
            },
        }

        _venueHub.updatePosition(this.props.channelId, data);
    }

    playerJoin = () => {
        const _this = this;
        const data = {
            playerId: _this.player.id,
            position: {
                x: _this.player.body.position.x.toString(),
                y: _this.player.body.position.y.toString(),
                z: _this.player.body.position.z.toString(),
            },
        }

        _venueHub.playerJoin(this.props.channelId, data);
    }

    joinVenue = () => {
        const _this = this;
        let callbacks = {
            playerJoin: _this.remotePlayerJoin,
            playerLeave: _this.remotePlayerLeave,
            updatePosition: _this.remoteUpdatePosition,
        }
        _venueHub.start(callbacks).then(_ => {
            _this.playerJoin();
        });
    }

    async componentDidMount() {
        let _this = this;
        _this.createScene();
        _this.createPlayer();
        _this.joinVenue();
        _this.engine.runRenderLoop(function () {
            // if (_this.player)
            //     _venueHub.updatePosition(_this.player.body.position);
            
            _this.scene.render();
        });
        window.addEventListener("beforeunload", _this.disconnect);

        await _this.startVR();
    }

    windowClose = () => {
        const _this = this;
    }

    disconnect = () => {
        const _this = this;
        _venueHub.playerLeave(this.props.channelId, { playerId: _this.player.id, position: {} }).then(_ => {
            _venueHub.stop();
        });
        return "";
    }

    async componentWillUnmount() {
        const _this = this;
        _this.disconnect();
        window.removeEventListener("beforeunload", _this.disconnect);
        document.getElementById('canvasVideo')?.pause();
    }

    render() {
        return (
            <React.Fragment>
                <canvas id="renderCanvas" style={{ width: "100%", height: "100%" }} ></canvas >
                <video id="canvasVideo" style={{ display: "none" }} autoPlay={this.props.isLive} playsInline src={this.state.stream} poster={_assets.getFileUrl(this.state.channelPictureId)}></video>
            </React.Fragment>
        );
    }
}
