import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import _users from '../services/userService';

class MasterLogin extends React.Component {
    state = {
        username: "",
        password: "",
        error: "",
    }

    login = async (e) => {
        e.preventDefault();
        // get user from db
        let user = await _users.masterLogin(this.state.username, this.state.password);
        if (!user) {
            this.setState({ error: "invalid login" });
        }
        else {
            this.props.masterLogin();
        }
    }

    changeForm = (e) => {
        let propName = e.target.name;
        let propValue = e.target.value;
        let state = { ...this.state };
        state[propName] = propValue;
        this.setState(state);
    }

    render() {
        const classes = {
            paper: {
                marginTop: "10px",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            form: {
                width: '100%',
                marginTop: "10px",
            },
            submit: {
                margin: "10px",
            },
        };
        let alert = this.state.error ? <Alert severity="error">{this.state.error}</Alert> : "";

        return (
            <Container component="main" maxWidth="sm" style={{ paddingTop: "10px" }}>
                <Paper elevation={3} style={{ padding: "2em", paddingBottom: "4em" }}>
                    <div style={classes.paper}>
                        <Avatar />
                        <Typography component="h1" variant="h5">Master Login</Typography>
                        <form style={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                onChange={this.changeForm}
                                value={this.state.username}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={this.changeForm}
                                value={this.state.password}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={classes.submit}
                                onClick={this.login}>
                                Log In
                            </Button>
                        </form>
                        {alert}
                    </div>
                </Paper>
            </Container >
        );
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = { };

export default connect(mapState, mapDispatch)(MasterLogin);