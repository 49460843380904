import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { AUTH_CLIENT_ID, AUTH_SERVER_URL, LOCAL_URL } from '../services/config';

export const _userManager = new UserManager({
    authority: `${AUTH_SERVER_URL}`,
    client_id: AUTH_CLIENT_ID,
    redirect_uri: `${LOCAL_URL}/auth/callback`,
    response_type: "code",
    scope: "openid profile api-scope offline_access",
    post_logout_redirect_uri: `${LOCAL_URL}/`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    revokeAccessTokenOnSignout: true,
    automaticSilentRenew: true,
    silent_redirect_uri: `${LOCAL_URL}/auth/silentCallback`,
});

// _userManager.events.addUserLoaded((u) => {
//     console.log(u);
// });
// _userManager.events.addUserUnloaded(() => {
//     console.log("user unloaded");
// });
// _userManager.events.addAccessTokenExpiring(() => {
//     console.log("access_token expiring");
// });
// _userManager.events.addSilentRenewError((e) => {
//     console.log(e);
// });
// _userManager.events.addUserSignedIn((e) => {
//     console.log(e);
// });
// _userManager.events.addUserSignedOut(() => {
//     console.log("user signed out");
// });
